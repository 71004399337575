:root {
	color-scheme: only light;
}

button {
	outline: none;
}

/* Prevent text selection */
// body,
// * {
// 	user-select: none; /* Standard syntax */
// 	-webkit-user-select: none; /* Safari and older Chrome */
// 	-ms-user-select: none; /* IE/Edge */
// 	-moz-user-select: none; /* Firefox */

// 	/* Disable touch interaction popups */
// 	-webkit-touch-callout: none; /* iOS Safari */
// }

.blinking-ripple {
	position: relative;
	overflow: hidden;
}

.blinking-ripple::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 300%;
	height: 300%;
	background: rgba(255, 0, 0, 0.5);
	border-radius: 50%;
	transform: translate(-50%, -50%) scale(0);
	animation: ripple 1.5s infinite;
	z-index: 0;
}

@keyframes ripple {
	0% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 1;
	}
	50% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0;
	}
	100% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0;
	}
}

.offline-cont {
	width: 100vw;
	height: 100vh;
	background-color: #0f121d;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.offline-title {
	color: #c1c4cf;
	font-size: 1.75rem;
}
.offline-list {
	color: #848aa0;
	font-size: 0.875rem;
}
.offline-list-item {
	color: #848aa0;
	font-size: 0.875rem;
}

.mirrorX {
	transform: rotateY(180deg);
}

.mirrorXwidth {
	width: 340px !important;
}

@media (max-width: 640px) {
	.mirrorXwidth {
		width: 295px !important;
	}
}

@media (prefers-color-scheme: dark) {
	/* Styles for dark mode */
	:root {
		color-scheme: light;
	}
}

::-webkit-calendar-picker-indicator {
	background-image: url('../assets/svg/calendar.svg');
}

@keyframes progressBarAnimation {
	0% {
		background-color: #232838;
		width: 0%;
	}

	30% {
		width: 100%;
		background-color: #84c70d;
	}

	100% {
		width: 100%;
		background-color: #84c70d;
	}
}

.progress-bar {
	width: 0%;
	height: 10px;
	animation: progressBarAnimation 10s linear forwards;
}

.video-react-control-bar.video-react-control-bar-auto-hide.controlbar {
	display: none !important;
}

.video-react {
	width: 250px !important;
	margin: auto !important;
	height: 100% !important;
}

.slick-slide.slick-cloned {
	transform: scale(0.5) !important;
	opacity: 0 !important;
}

/* Start Style the carousel dots */
.slick-prev,
.slick-next {
	display: none !important;
}

.slick-dots {
	position: absolute;
	bottom: -30px;
	list-style: none;
	text-align: center;
	display: flex;
	justify-content: center;
}

.slick-dots li button:before {
	color: transparent !important;
}

.slick-dots li {
	margin: 0 5px;
	width: auto !important;
}

.slick-dots li button {
	width: 14px;
	height: 10px;
	border-radius: 8px;
	background-color: #656c64;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.slick-dots li.slick-active button {
	background-color: #84c70d;
	width: 25px;
}

/* End Style the carousel dots */

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
	width: 150px !important;
	height: 150px !important;
}

img.reactEasyCrop_Image.reactEasyCrop_Contain {
	width: 300px !important;
	object-fit: contain !important;
}

.driver-popover.jackpot {
	background-color: #1d202b !important;
	color: white !important;
	border-radius: 20px;
	padding: 20px;
}

.jackpot .driver-popover-close-btn {
	color: #fff !important;
	font-size: 30px !important;
}

.jackpot .driver-popover-title {
	margin-top: 10px !important;
}

.jackpot .driver-active-element {
	border: 2px dashed #84c70d !important;
	border-radius: 5px;
	padding: auto 10px;
	padding: 3px 6px;
	// position: relative;
	// top: -5px;
	// left: -5px;
}

.jackpot .driver-popover-arrow {
	border: 10px solid #1d202b !important;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
	border-top-color: transparent !important;
}

.jackpot button.driver-popover-prev-btn.driver-popover-btn-disabled {
	display: none !important;
}

.jackpot span.driver-popover-progress-text {
	position: absolute !important;
	top: 35px !important;
	right: 15px !important;
	color: #848aa0 !important;
	font-weight: 700 !important;
}

.jackpot .driver-popover-footer button {
	background: #84c70d !important;
	color: #0f121d !important;
	border-radius: 14px;
	font-size: 14px;
	font-weight: 700;
	text-shadow: none;
	border: none;
	padding: 4px 9px;
}

.free-spin-banner {
	background-image: url('../assets/images/advert-free-spin.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.jackpot-banner {
	background-image: url('../assets/images/jackpot-banner2.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hammerdaily {
	background-image: url('../assets/images/hammerdaily.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.hammermega {
	background-image: url('../assets/images/hammermega.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.jackpot-success {
	background-image: url('../assets/images/jackpot-success.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.nav-avatar {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.text-gradient {
	background: linear-gradient(180deg, #fee715 0%, #69d08d 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.custom-box-shadow {
	box-shadow: 0px 3.3166942596435547px 3.3166942596435547px 0px
		rgba(0, 0, 0, 0.25) inset;
}

.jackpot-box-shadow {
	box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

// .progress-bar {
// 	background-color: #5F3E2C;
// }

.weekly-draw {
	background-image: url('../assets/images/weekly-draws-trophy.svg');
	background-position: 95% center;
	background-repeat: no-repeat;
	background-size: initial;

	@include screen('inbetween') {
		background-position: 98% center;
		background-size: 40% 70%;
	}
}

.free-game {
	background-image: url('../assets/images/free-spin.png');
	background-position: right center;
	background-repeat: no-repeat;
	background-size: contain;

	@include screen('inbetween') {
		background-size: contain;
	}
}

.free-ad-gem {
	background-image: url('../assets/images/free-gems.png');
	background-position: 95% 20%;
	background-repeat: no-repeat;
	background-size: initial;

	@include screen('inbetween') {
		background-size: 40% 70%;
		background-position: 98% center;
	}
}

.leaderboard-header {
	// background-image: url("../assets/images/leaderboard-bg.svg");
	// background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.leaderboard-row {
	// opacity: 0.30000001192092896;
	// background: linear-gradient(
	// 	90deg,
	// 	rgba(82, 88, 109, 0) 0%,
	// 	#52586d 19.71%,
	// 	#52586d 77.28%,
	// 	rgba(82, 88, 109, 0) 100%
	// );
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 2.26%,
		rgba(255, 255, 255, 0.04) 21.21%,
		rgba(255, 255, 255, 0.04) 76.54%,
		rgba(255, 255, 255, 0) 98.38%
	);
}

.score-row {
	background: var(
		--colors-linear-linear-1,
		linear-gradient(
			90deg,
			rgba(82, 88, 109, 0) 2.26%,
			rgba(82, 88, 109, 0.3) 21.21%,
			rgba(82, 88, 109, 0.3) 76.54%,
			rgba(82, 88, 109, 0) 98.38%
		)
	);
}

.history-jackpot-title {
	border-radius: 9px;
	background: var(
		--colors-linear-linear-1,
		linear-gradient(
			90deg,
			rgba(82, 88, 109, 0) 0%,
			rgba(82, 88, 109, 0.3) 19.71%,
			rgba(82, 88, 109, 0.3) 77.28%,
			rgba(82, 88, 109, 0) 100%
		)
	);
}

.text-vag_rounded {
	font-family: 'vag_rounded';
}

.brown-text-shadow {
	text-shadow: '1px 1px #8B3520';
	-webkit-text-fill-color: #ffffff;
	-webkit-text-stroke: 1.2px #8b3520;
}

// text {
// 	fill: white;
// 	stroke: #8b3520;
// 	stroke-width: 1px;
// 	stroke-linejoin: round;
// }

// .free-spin-banner {
// 	background-image: url('../assets/images/free-daily-hammer.png');
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: cover;
// }

.hammer-loader-title {
	background: linear-gradient(180deg, #fee715 0%, #69d08d 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-align: center;
	font-family: 'vag_rounded';
}

.hammer-spin-bg {
	background-image: url('../assets/seasons/active/spin/hammer-spin-default-bg.png');
	background-position: center 80%;
}

.free-spin-bg {
	background-image: url('../assets/images/hammer-free-spin-web-bg.svg');
	background-position: center 80%;
}

@media screen and (max-width: 480px) {
	.hammer-spin-bg {
		background-image: url('../assets/seasons/active/spin/hammer-spin-default-bg.png');
		background-position: center;
	}

	.free-spin-bg {
		background-image: url('../assets/seasons/active/spin/hammer-free-spin-mobile-bg.png');
		background-position: center 80%;
	}
}

.hammer-spin-wheel-bg {
	filter: drop-shadow(0px 13px 18px rgba(5, 30, 25, 0.69));
}

.hammer-spin-wheel-bg:before {
	background-image: url('../assets/images/hammer-spin-wheel-max.svg');
	position: absolute;
	content: '';
	height: 115%;
	display: block;
	width: 115%;
	top: -4%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;
}

@media screen and (max-width: 480px) {
	.hammer-spin-wheel-bg:before {
		background-image: url('../assets/images/hammer-spin-wheel.svg');
		position: absolute;
		content: '';
		height: 106%;
		display: block;
		width: 106%;
		top: -3%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto;
		border-radius: 50%;
	}
}

.touch-action-auto {
	touch-action: auto !important; /* Use !important to ensure it overrides any other styles */
}

.hammer-spin-notifications {
	background: #d2facc;
	box-shadow: 0px 4px 0px 0px #14563c;
}

.hammer-spin-brown-bg {
	border-radius: 20px;
	background: #8b3520;
	box-shadow: 5px 9px 4px 0px #5b2514 inset;
}

.hammer-spin-wheel {
	// animation: 30s linear;

	.wheel-slice {
		fill: #0f4436;
		stroke-width: 2px;
		stroke: #509538;
		clip-path: polygon(0 0, 0% 0, 100% 100%, 0 50%);
		background: white;
	}

	span {
		top: 14%;
		right: 0%;
		font-size: 17px;
	}
}

@media screen and (max-width: 480px) {
	.hammer-spin-wheel {
		span {
			font-size: 11px;
		}
	}
}

.marque {
	display: flex;
	width: 100%;
	gap: 3rem;
	overflow: hidden;
	user-select: none;
	//   border: 2px solid red;
}

.marque-group {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	white-space: nowrap;
}

#scroll-1 {
	animation: scroll_x_one 135s linear infinite;
}

#scroll-2 {
	animation: scroll_x_one 135s linear infinite;
	animation-delay: 135s;
}

@keyframes scroll_x_one {
	from {
		left: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

@keyframes scroll_x_two {
	from {
		left: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

@media screen and (max-width: 1120px) {
	.marquee-yo {
		p {
			flex-shrink: 0;
			white-space: nowrap;
		}
	}
}

.marquee-yo {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
	animation: scrolling 10s linear infinite;

	p {
		flex-shrink: 0;
		// white-space: nowrap;
		// width: 100%;
	}

	p:hover {
		animation-play-state: paused;
	}
}

.marquee-pops {
	position: relative;
}

.marquee-pops:before,
.marquee-pops:after {
	position: absolute;
	top: 0;
	width: 3rem;
	height: 100%;
	content: '';
	z-index: 1;
}

.marquee-pops:before {
	left: 0;
	/* Safari-specific rule */
	@supports (-webkit-appearance: none) {
		background: -webkit-linear-gradient(
			to right,
			#d2facc,
			rgba(210, 250, 204, 0) 79.5%
		);
	}
	/* Standard rule */
	background: linear-gradient(to right, #d2facc, rgba(210, 250, 204, 0) 79.5%);
}

.marquee-pops:after {
	right: 0;
	/* Safari-specific rule */
	@supports (-webkit-appearance: none) {
		background: -webkit-linear-gradient(
			to right,
			#d2facc,
			rgba(210, 250, 204, 0) 79.5%
		);
	}
	/* Standard rule */
	background: linear-gradient(to left, #d2facc, rgba(210, 250, 204, 0) 79.5%);
}

@keyframes scrolling {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

.bulb {
	width: 9px;
	height: 9px;
	border-radius: 50%;
	position: absolute;
	left: -5px;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	transform: translate(-50%, -50%);
	background-blend-mode: screen;
	background: radial-gradient(
		49.96% 49.96% at 49.89% 50.12%,
		#fff371 18%,
		#ffec5f 37%,
		#ffd93e 57%,
		#ffad08 89%,
		#ffab07 98%
	);
	box-shadow:
		1px 1px 1px 1px rgba(4, 26, 22, 0.53),
		1px 2px 2px 5px rgb(234 234 234 / 12%);
}

@media screen and (max-width: 480px) {
	.bulb {
		width: 9px;
		height: 9px;
	}
}

@keyframes blink {
	0% {
		box-shadow: 0px 0px 7px 6px #d19b52;
	}

	100% {
		box-shadow: 0px 0px 12px 2px #d19b52;
	}
}

.animate .bulb {
	animation: blink 0.5s infinite alternate;
}

.circler {
	width: 0;
	height: 0;
	border: 100px solid transparent;
	border-top-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-radius: 50%;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.glassy-shadow {
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;

	svg {
		width: 100%;
		/* Make the SVG width 100% of its container */
		height: 100%;
		/* Allow the SVG to maintain its aspect ratio */
		transform-origin: 0 0;
		/* Set the transformation origin to the top-left corner */
		position: absolute;
		/* Position absolutely within its container */
	}
}

.half-wheel {
	width: 100%;
	height: 0;
	overflow: hidden;
	position: relative;
}

.spin-reward-text {
	background: linear-gradient(177deg, #ffffc3 2.2%, #e4e32a 228.17%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.free-spin-btn {
	background: linear-gradient(180deg, #22824b 0%, #0b4327 100%);
	box-shadow: 0px 6.8663px 0px 0px #011c13;
	transition: 0.1s all ease-in-out;
}

.free-spin-btn:hover {
	box-shadow: 0px 6.8663px 0px 0px #011c130a;
}

.free-spin-amt {
	box-shadow: 2px 6px 0px 0px #011c13;
}

.bonus-title {
	border-radius: 0rem 0rem 6.52163rem 5.92875rem;
}

.odogwu-cont {
	background-image: url('../assets/seasons/active/hunter/hunter-mobile-bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;

	@include screen('inbetween') {
		background-image: url('../assets/seasons/active/hunter/hunter-mobile-bg.png');
	}
}

// .odogwu-mobile-cont {
// 	background-image: url("../assets/images/hunter-mobile-bg.png");
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: 100% 100%;
// }

.odogwu-nav-reward {
	background-image: url('../assets/odogwu-icons/odogwu-nav-reward.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.odogwu-nav-cowry {
	background-image: url('../assets/odogwu-icons/odogwu-nav-cowry-plus.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.odogwu-left {
	background-image: url('../assets/odogwu-icons/hunter-board.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	// border: 2px solid blue;
}

.odogwu-right {
	.right-top {
		background-image: url('../assets/odogwu-icons/odogwu-board-bg.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 103% 102%;
	}
}

.first {
	background-image: url('../assets/odogwu-icons/odogwu-control-bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	// border: 2px solid blue;
}

.cancel-btn {
	background-image: url('../assets/odogwu-icons/cancel-btn.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.cashout-btn {
	background-image: url('../assets/seasons/active/hunter/cashout-btn.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.hunt-btn {
	background-image: url('../assets/odogwu-icons/aim-btn.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.odogwu-modal-wrapper {
	width: 40rem !important;
	max-width: 50rem !important;
	// border: 2px solid red;

	@include screen('inbetween') {
		width: 100% !important;
		max-width: 100% !important;
		// border: 2px solid blue;
	}
}

.odogwu-modal {
	// border: 2px solid yellow;

	background-image: url('../assets/odogwu-icons/odogwu-leaderboard-bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.leader-topic {
	background-image: url('../assets/odogwu-icons/odogwu-leader-head.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.balance-counter {
	text-align: center;
	font-weight: bold;
	animation: countUp 2s ease-in-out;
	/* Apply the animation to the .counter element */
}

@keyframes countUp {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(1.1);
		opacity: 0;
	}
}

.network-modal-cont {
	// background: #3D0C00;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.network-modal {
		background-image: url('../assets/odogwu-icons/network-modal-bg.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

.pulse-animation {
	background: white;
	border-color: #ff6600;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.driver-popover.hunter-tour-popup {
	// background-color: transparent;
	// box-shadow: none;
}

.scroll-bar-cont::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-bar-cont {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.power-up-active-border {
	animation: borderColorToggle 2s infinite alternate;
}

@keyframes borderColorToggle {
	25% {
		border-style: dotted;
		border-color: red;
		color: red;
		background-color: red;
	}

	50% {
		border-style: double;
		border-color: #84c70d;
		color: #84c70d;
		background-color: #84c70d;
	}

	75% {
		border-style: dashed;
		border-color: #3a98b9;
		color: #3a98b9;
		background-color: #3a98b9;
	}

	100% {
		border-style: solid;
		border-color: yellow;
		color: yellow;
		background-color: yellow;
	}
}

.hunter-toast-cont {
}

.driver-popover.spin-driverjs-theme {
	background-color: #edc789;
	color: #70220f;
}

.driver-popover.spin-driverjs-theme .driver-popover-title {
	font-size: 20px;
	font-weight: 700;
}

.driver-popover.spin-driverjs-theme .driver-popover-title,
.driver-popover.spin-driverjs-theme .driver-popover-description,
.driver-popover.spin-driverjs-theme .driver-popover-progress-text {
	color: #70220f;
	font-family: 'Space Grotesk', sans-serif !important;
	font-weight: 600;
}

.driver-popover.spin-driverjs-theme .driver-popover-prev-btn,
.driver-popover.spin-driverjs-theme .driver-popover-next-btn {
	color: #ffee00;
	background: linear-gradient(180deg, #22824b 0%, #0b4327 100%);
	border-radius: 14px;
	border: 0;
	padding: 8px 15px;
	height: 35px;
	font-family: 'Space Grotesk', sans-serif !important;
	font-weight: 600;
	font-size: 14px;
	text-shadow: none;
}

.win-amount::after {
	content: '';
	position: absolute;
	top: -9.2px;
	border-radius: 65px;
	right: -9.2px;
	left: -9.2px;
	bottom: -9.2px;
	border: 9.2px solid rgba(116, 235, 18, 0.46);
}

.spin-btns-green img {
	filter: drop-shadow(0px 3.497px 0px #053c23);
	transition: 0.1s all ease-in-out;
	cursor: pointer;
}

.spin-btns-green img:hover {
	filter: none;
}

.spin-btns-brown {
	filter: drop-shadow(0px 8.575px 0px #661806);
	transition: 0.1s all ease-in-out;
}

.spin-btns-brown:hover {
	filter: none;
}

.hunter-story-board {
	background-image: url('../assets/odogwu-icons/hunter-story-board.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	height: 120%;

	@include screen('large') {
		background-image: url('../assets/odogwu-icons/hunter-story-board-2.svg');
	}
	@include screen('small') {
		height: 60%;
	}
}

.staking-slider {
	border: 5px solid #8b3520;
	background: #8b3520;
	border-radius: 0.375rem;
}

.staking-slider label {
	display: flex;
	align-items: center;
	background: #ffcb05;
	width: 100%;
	padding: 0.25rem 0.425rem;
	border-radius: 0.5rem;
}

.staking-slider input[type='range'] {
	-webkit-appearance: none;
	appearance: none;
	background: transparent;
	cursor: pointer;
	border-radius: 0.5rem;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.staking-slider input[type='range']::-webkit-slider-runnable-track {
	background: #ffee00;
	height: 0.4rem;
}

/******** Firefox ********/
.staking-slider input[type='range']::-moz-range-track {
	background: #ffee00;
	height: 0.4rem;
}

/***** Thumb Styles *****/
/* Thumb: webkit */
input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: none;
	height: 2.5rem;
	width: 2.5rem;
	background: #8b3520;
	background: url('../assets/icons/spin-range-thumb.png') no-repeat;
	// margin-top: -0.5rem;
	border-radius: 0 !important;
	position: relative;
	z-index: 10;
	top: -1rem;
	background-size: cover;
}

.ripple-button {
	position: fixed;
	bottom: 5%;
	right: 30px;
	background: #6ca50a; /* Green theme color */
	color: white;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

/* For small devices like phones */
@media (max-width: 768px) {
	.ripple-button {
		bottom: 12%;
		right: 20px;
	}
}

.ripple-button::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	background-image: radial-gradient(circle, #ffffff40 10%, transparent 10.01%);
	background-repeat: no-repeat;
	background-position: 50%;
	transform: scale(10, 10);
	opacity: 0;
	transition:
		transform 0.5s,
		opacity 1s;
	animation: rippleEffect 1.5s infinite ease-in-out;
}

@keyframes rippleEffect {
	0% {
		transform: scale(0);
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		transform: scale(10);
		opacity: 0;
	}
}

/***** Firefox *****/
.staking-slider input[type='range']::-moz-range-thumb {
	border: none; /*Removes extra border that FF applies*/
	border-radius: 0; /*Removes default border-radius that FF applies*/
	height: 2.5rem;
	width: 2.5rem;
	background: #8b3520;
	background: url('../assets/icons/spin-range-thumb.png') no-repeat;
	// margin-top: -0.5rem;
	border-radius: 0 !important;
	position: relative;
	z-index: 10;
	top: -1rem;
	background-size: cover;
}

.vertical-scroll-bar::-webkit-scrollbar {
	width: 0.3rem;
}
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #84c70d;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}

.gradient-text h2 {
	background: linear-gradient(178.51deg, #c1fd55 23.32%, #75aa17 149.25%);
	/* Fallback for browsers that do not support gradients */
	background: #75aa17; /* Old browsers */
	background: -moz-linear-gradient(
		178.51deg,
		#c1fd55 23.32%,
		#75aa17 149.25%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		178.51deg,
		#c1fd55 23.32%,
		#75aa17 149.25%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		178.51deg,
		#c1fd55 23.32%,
		#75aa17 149.25%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	/* Fallback text color if background clip is not supported */
	color: white; /* Fallback */
}

.text-shadow-dark-1 {
	text-shadow:
		0px 3.13px 1.46px #000000cf,
		0px 2.17px 4.17px #03271f,
		0 6.5px 1px #001c16;
	-webkit-text-stroke: 1.2px #03271f;
	color: #ffffff;
}

.text-shadow-dark-2 {
	text-shadow:
		0px 3.13px 1.46px rgba(0, 0, 0, 0.8117647059),
		0px 2.17px 4.17px #03271f,
		0 7.25px 1px #001c16,
		3px 33px 65px #76b700cc,
		3px 33px 65px #76b700cc,
		3px 15px 65px #76b700cc;
	-webkit-text-stroke: 2px #03271f;
	color: #c1fd55;
}

.inner {
	white-space: nowrap;
	transition: transform 0.3s;
}

.carousel-item {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.carousel-item {
		width: 50% !important;
	}
}

.user-chat {
	border-radius: 12px 12px 0 12px;
}
.others-chat {
	border-radius: 0 12px 12px 12px;
}
.chat-display-top {
	border-radius: 10px 10px 0 0;
}

.hunter-xter-bg {
	background-image: url('../assets/images/hunter-character-bg.svg');

	@include screen('inbetween') {
		background-image: url('../assets/odogwu-icons/xter-bg-mobile.png');
	}
}

.primary-promo-text {
	color: transparent;
	display: block;
	-webkit-text-stroke-width: 5px;
	-webkit-text-stroke-color: #063a2f;
	text-shadow:
		-1px 1px 0 #c1fd55,
		1px 5px 0 #063a2f,
		1px 8px 0 #0a1e1a,
		2px 11px 3px #000,
		0px 0px 24px #84c70f;
}

.sub-promo-text {
	color: transparent;
	display: inline-block;
	-webkit-text-stroke-width: 3px;
	-webkit-text-stroke-color: #063a2f;
	text-shadow:
		-1px 1px 0 #c1fd55,
		1px -3px 0 #063a2f,
		1px 2px 0 #164037,
		1px 6px 0px #000,
		0px 0px 19px #84c70f;

	transform: translate(25%, -10%);
}

.free-gift-text {
	color: #e7ffbc;
	-webkit-text-stroke-width: 0.4px;
	-webkit-text-stroke-color: #063a2f;
}

.winners-date-picker {
	border: 2px solid red;

	input {
		width: 6.5rem;
		color: white;
		font-size: 0.85rem;
		padding: 0.25rem;
		outline: none;
		border: none;
		background: transparent;
	}
}

.date-picker-calendar {
	border: 2px solid blue;
}

// .bot-modal-cont {
// 	background-color: #0E111A;
// 	background-opacity
// }

.btn-secondary-gradient {
	background: linear-gradient(180deg, #ffffff 0%, #eaffc5 100%);
	box-shadow: 0px -1.79px 1.52px 0.36px #7ab55f inset;
	box-shadow: 0px 7.14px 5.76px -2.88px #00000066;
	box-shadow: 0px 2.68px 0px 0px #759a51;
}

.button3d-disabled {
	background: linear-gradient(184.59deg, #b5b4b2 6.69%, #a09f9d 76.86%);
	box-shadow: 0px -8px 6.1px 0px #7d7d7d inset;
}

.button3d-active {
	background:
		linear-gradient(184.59deg, #adff00 6.69%, #89f500 76.86%),
		radial-gradient(
				37.73% 107.67% at 24.79% 50.22%,
				#bcfe00 0%,
				rgba(173, 255, 0, 0) 100%
			)
			/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
		radial-gradient(
				47.82% 81.12% at 80.47% 47.76%,
				#9eff00 0%,
				rgba(113, 201, 2, 0) 100%
			)
			/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
	box-shadow: 0px 10px 0px 0px #7ca508;
	box-shadow: 0px -8px 6.1px 0px #bbf939 inset;
	// box-shadow: 0px 6px 4px 0px #77ac05ba;
}

.button3d-active-shadow {
	box-shadow: 0px 8px 0px 0px #7ca508;
	border-radius: 34px;
}
.button3d-active-shadow:hover,
.button3d-active-shadow:active {
	box-shadow: none;
	border-radius: 34px;
}

.button3dmini-active-shadow {
	box-shadow: 0px 5px 0px 0px #7ca508;
	border-radius: 34px;
}
.button3dmini-active-shadow:hover,
.button3dmini-active-shadow:active {
	box-shadow: none;
	border-radius: 34px;
}

.button3d-text {
	-webkit-text-fill-color: #000000;
	-webkit-text-stroke-width: 0px;
	// text-shadow:
	// 	#376003 -1px -1px 0px,
	// 	#376003 0px -1px 0px,
	// 	#376003 1px -1px 0px,
	// 	#376003 1px 0px 0px,
	// 	#376003 1px 1px 0px,
	// 	#376003 0px 1px 0px,
	// 	#376003 -1px 1px 0px,
	// 	#376003 -1px 0px 0px;
	filter: drop-shadow(#c2ff85 0px 3px 0px);
}

.button3d-disabled-text {
	-webkit-text-fill-color: #ffffff;
	-webkit-text-stroke-width: 0px;
	text-shadow:
		#666666 -1px -1px 0px,
		#666666 0px -1px 0px,
		#666666 1px -1px 0px,
		#666666 1px 0px 0px,
		#666666 1px 1px 0px,
		#666666 0px 1px 0px,
		#666666 -1px 1px 0px,
		#666666 -1px 0px 0px;
	filter: drop-shadow(#c3c3c3 0px 3px 0px);
}

.btn-shadow {
	border: 1px solid #a7eb2f;
	box-shadow: 0px -2px 1.8px 0px #00000040 inset;
}

.language-background {
	position: relative;
}
.language-background::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #030f2e 21.28%, #0b1db8 52.82%),
		linear-gradient(0deg, #670769, #670769),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
}

.welcome-background {
	position: relative;
}
.welcome-background::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #030f2e 21.28%, #3817ff 52.82%),
		linear-gradient(0deg, #8c02cd, #8c02cd),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
}

.play-background::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #030f2e 21.28%, #ff17b0 52.82%),
		linear-gradient(0deg, #023bcd, #023bcd),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
}

.connect-background::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #030f2e 21.28%, #17ffd5 52.82%),
		linear-gradient(0deg, #1e02cd, #1e02cd),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
}

.subscribe-background1::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #050d52 21.28%, #5417ff 52.82%),
		linear-gradient(0deg, #a402cd, #a402cd),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
}

.subscribe-background2::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #029ccd, #029ccd),
		linear-gradient(334.78deg, #305205 45.11%, #6fff17 60.51%),
		linear-gradient(0deg, #0d25a5, #0d25a5),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
}

.country-background::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #050d52 21.28%, #5417ff 52.82%),
		linear-gradient(0deg, #a402cd, #a402cd),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
	z-index: -1;
}

.loading-page-bg {
	position: relative;
	z-index: 0;
}

.loading-page-bg::before {
	content: '';
	position: absolute;
	top: -10%;
	left: -10%;
	width: 120%;
	height: 120%;
	background:
		linear-gradient(0deg, #026bcd, #026bcd),
		linear-gradient(338.61deg, #030f2e 21.28%, #3817ff 52.82%),
		linear-gradient(0deg, #8c02cd, #8c02cd),
		linear-gradient(67.11deg, #59acf9 7.03%, rgba(89, 249, 172, 0) 47.32%);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 70% 50%;
	filter: blur(715.68px);
	z-index: -1;
}

.special-offer-bg {
	position: relative;
	z-index: 0;
	backdrop-filter: blur(200px);
}

.special-offer-bg::before {
	content: '';
	position: absolute;
	top: 0%;
	left: -10%;
	width: 100%;
	height: 100%;
	background:
		linear-gradient(0deg, #cd8602, #cd8602),
		linear-gradient(0deg, #ff3a17, #ff3a17),
		linear-gradient(0deg, #afee02, #afee02),
		linear-gradient(
			301.08deg,
			rgba(252, 178, 91, 0.91) 20.42%,
			rgba(252, 178, 91, 0) 60.38%
		);
	background-position:
		top left,
		top left,
		bottom right,
		bottom right;
	background-repeat: no-repeat;
	background-size: 100% 50%;
	filter: blur(715.68px);
	z-index: -1;
}
